import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { useHeaderUpdater } from "@components/HeaderContextProvider";
import { sliderContent } from "@components/Onboarding/Onboarding";
import {
  BackIconButton,
  OnBoardingDot,
  OnBoardingDotsContainer,
  OnBoardingNavigation,
} from "@components/Onboarding/styles/onboardingSlide.styled";
import { ButtonPrimaryDark, ButtonSecondaryDark, ButtonTertiaryDark } from "@components/buttons";

const OnboardingNav = ({
  currentSlide,
  setCurrentSlide,
  setAnimationForward,
}: {
  currentSlide: number;
  setCurrentSlide: Dispatch<SetStateAction<number>>;
  setAnimationForward: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { setShowOnBoarding } = useHeaderUpdater();

  const nextSlide = () => {
    if (currentSlide >= sliderContent.length - 1) return;
    setAnimationForward(true);
    setCurrentSlide((prev) => prev + 1);
  };
  const closeModal = () => {
    setShowOnBoarding(false);
  };
  const goToSlide = (slide: number) => {
    setCurrentSlide(slide);
    if (currentSlide > slide) {
      setAnimationForward(false);
    } else {
      setAnimationForward(true);
    }
  };

  return (
    <OnBoardingNavigation>
      <div>
        {currentSlide !== 0 && (
          <BackIconButton
            variant="arrow-left"
            size="xxl"
            onClick={() => {
              setCurrentSlide((prev) => prev - 1);
              setAnimationForward(false);
            }}
          />
        )}
        <OnBoardingDotsContainer>
          {new Array(...new Array(sliderContent.length).keys()).map((index: number) => {
            const isActive = currentSlide === index;
            return (
              <OnBoardingDot
                key={index}
                className={isActive ? "active" : ""}
                onClick={() => goToSlide(index)}
              />
            );
          })}
        </OnBoardingDotsContainer>
      </div>
      {currentSlide !== sliderContent.length - 1 ? (
        <div>
          <ButtonTertiaryDark data-testid="close-onboarding" onClick={closeModal}>
            {t("onboarding.closeOnboarding")}
          </ButtonTertiaryDark>
          <ButtonPrimaryDark icon={null} onClick={nextSlide}>
            {t("onboarding.nextSlide")}
          </ButtonPrimaryDark>
        </div>
      ) : (
        <div>
          <ButtonSecondaryDark onClick={closeModal} icon={null}>
            {t("onboarding.finishOnboarding")}
          </ButtonSecondaryDark>
        </div>
      )}
    </OnBoardingNavigation>
  );
};
export default OnboardingNav;
